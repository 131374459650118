<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <!-- Người lao động -->
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Người lao động <span class="required"></span></label>
              <v-select
                :reduce="label => label.value"
                label="label"
                :options="isOversea"
                :placeholder="'Lựa chọn người lao động'"
                @input="filterWorker"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <!-- Loại hợp đồng lao động -->
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Loại hợp đồng lao động <span class="required"></span></label>
              <v-select
                :reduce="label => label.value"
                label="label"
                :options="contractType"
                :placeholder="'Lựa chọn loại hợp đồng lao động'"
                @input="filterTypeContract"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <!-- Trình độ học vấn -->
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trình độ học vấn <span class="required"></span></label>
              <v-select
                :reduce="label => label.value"
                label="label"
                :options="academicLevel"
                :placeholder="'Lựa chọn trình độ học vấn'"
                @input="filterStudy"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="InputHelp">Doanh nghiệp</label>
              <v-select
                v-model="urlQuery.bussinessId"
                :reduce="title => title.id"
                label="name"
                :disabled="userData.roleIdentity === 'EducationDepartment'"
                :options="dataAllBusiness || []"
                :placeholder="'Doanh nghiệp'"
                @input="filterBussiness"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Trạng thái -->
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trạng thái <span class="required"></span></label>
              <v-select
                :reduce="label => label.status"
                label="statusString"
                :options="status"
                :placeholder="'Lựa chọn trạng thái'"
                @input="filterStatus"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :contentBtnAdd="'Thêm người lao động'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="dowloadFile"
        @clickExportExcel="downloadExcelAll"
        @importFile="importFileExcel($event)"
        @clickAdd="$router.push({name: 'manage-workers-add'})"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          ref="user-table"
          :columns="columns"
          :rows="dataTable || []"
          style-class="vgt-table"
          :select-options="{
            enabled: true,
            vertialAlignTop: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          @on-selected-rows-change="selectRowTable"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field == 'statusString' "
            >
              <b-badge
                pill
                :variant="colorStatus(props.row.status)"
                class="border-status"
              >
                <span> {{ props.row.statusString }}</span>
              </b-badge>
            </span>
            <!-- Chức năng -->
            <span v-else-if="props.column.field == 'cn'">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Chỉnh sửa'"
                  icon="EditIcon"
                  size="18"
                  @click="$router.push({name: 'manage-workers-edit', params: {id: props.row.id}})"
                />
                <feather-icon
                  v-b-tooltip.hover.top="'Xóa'"
                  icon="Trash2Icon"
                  size="18"
                  style="margin-left: 14px"
                  @click.stop="deleteItem(props.row.id)"
                />
              </span>

            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </template>
      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :title="''"
        :content="modalContent"
        @accept="deleteAction"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, VBTooltip,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import DataFilter from '../../worker-permit/constants/DataFilter'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    ConfirmModal,
    BBadge,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isOversea: DataFilter.isOversea,
      status: DataFilter.dataStatus,
      contractType: DataFilter.contractType,
      academicLevel: DataFilter.academicLevel,
      // ==dữ liệu bảng
      dataTable: [],
      totalRecord: 0,
      columns: [
        {
          label: 'HỌ TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
        },
        {
          label: 'QUỐC TỊCH',
          field: 'country',

        },
        {
          label: 'TRÌNH ĐỘ HỌC VẤN',
          field: 'academicLevelString',
        },
        {
          label: 'LOẠI HỢP ĐỒNG',
          field: 'contractTypeString',
        },
        {
          label: 'TRẠNG THÁI',
          field: 'statusString',
        },
        {
          label: 'CHỨC NĂNG',
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',

          field: 'cn',
        },
      ],
      // ==phân trang
      totalPages: 0,
      // ==Dữ liệu fetch list
      urlQuery: {
        isOversea: null, // Lao động trong nước hay ngoài nước
        status: '', // Trạng thái cấp phép
        contractType: '', // lọc theo loại hợp đồng
        academicLevel: '', // Lọc theo trình độ học vấn
        key: '',
        pageSize: 10,
        pageNumber: 1,
        bussinessId: '',
      },
      // xử lý xóa
      showBtnMultiDelete: false,
      confirmModalId: 'confirmModalId',
      modalContent: 'Bạn có chắc chắn muốn xóa',
      isSelfEmployment: true,
      // file
      arrayExcel: ['code', 'name', 'birthDay', 'gender', 'identityCardNumber', 'country', 'address', 'email', 'phoneNumber', 'academicLevel', 'position', 'contractType', 'typeOfProfession'],
    }
  },
  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),

    colorStatus() {
      const statusColor = {
        None: 'info',
        NonLiscening: 'secondary',
        PendingConfirm: 'warning',
        Confirm: 'success',
        RejectConfirm: 'danger',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        PendingLicensing: 'warning',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },
  },
  mounted() {
    this.fetchDataComboboxAllBusiness(this.isSelfEmployment)
  },
  // ===========================CREATED====================
  async created() {
    if (this.userData.roleIdentity === 'EducationDepartment') {
      this.urlQuery.bussinessId = 'td'
    }
    await this.doFetchListWorker(this.urlQuery)
  },
  // =============================METHOD======================
  methods: {
    ...mapActions('worker', ['dowloadFile', 'downloadExcelAll', 'getApiExcel']),
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    //* ****API *****//
    // ***lấy dữ liệu bảng***
    // eslint-disable-next-line consistent-return
    async doFetchListWorker(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.DO_FETCH_LIST_WORKERS, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    //* **Phân trang***//
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.doFetchListWorker(this.urlQuery)
    },
    // lấy danh sách khi tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },

    filterWorker(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.isOversea = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.isOversea = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },

    filterTypeContract(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.contractType = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.contractType = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },

    filterStudy(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.academicLevel = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.academicLevel = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },

    filterStatus(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },
    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.doFetchListWorker(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.doFetchListWorker(this.urlQuery)
      }
    },
    // =====================Xóa=====================
    // Mở modal xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = [id]
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },
    // Mở modal xóa nhiều
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = this.$t('Bạn có muốn xóa mục đã chọn không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DO_DELETE_WORKER, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.doFetchListWorker(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // chọn hàng table
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    // ==============File===========
    // import file exel
    async importFileExcel(event) {
      if (event) {
        await this.getValidData(event)
      }
    },
    // kiểm tra hợp lệ từ file exel
    async getValidData(dataInput) {
      const datafile = dataInput.map(element => ({
        ...element,
        birthDay: Date.parse(element.birthDay) ? `${new Date(element.birthDay).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
      }))
      const model = {
        listExcel: datafile,
        isValidate: true,
      }
      await this.getApiExcel(model)
      this.$router.push({ name: 'manage-workers-import' })
    },
  },
}
</script>

<style lang="scss">
#filter-custom {
  .header {
    font-size: 18px;
    color: #181f28;
  }
}
.border-status {
  border-radius: 4px;
}
</style>
